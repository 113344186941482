import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import readingTime from 'reading-time'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tags from '../components/tags'
import * as styles from './blog-post.module.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw),
    )
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const { minutes: timeToRead } = readingTime(plainTextBody)

    const richTextImages = {}

    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        [MARKS.ITALIC]: text => <i>{text}</i>,
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const imageData = richTextImages[node.data.target.sys.id]
          return (
            <GatsbyImage image={imageData.image} alt={imageData.alt} />
          )
        },
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      },
      renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />,
        text]),
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`http:${post.heroImage.resize.src}`}
        />
        <Hero
          image={post.heroImage?.gatsbyImage}
          title={post.title}
          content={post.description}
        />
        <div className={styles.container}>
          <div className={styles.meta}>
            {post.author?.name} &middot;{' '}
            <time dateTime={post.rawDate}>{post.publishDate}</time>
            {' '}
          </div>
          <div className={styles.meta}>{timeToRead} minute read</div>
          <div className={styles.article}>
            <div className={styles.body}>
              {post.body?.raw && renderRichText(post.body, options)}
            </div>
            <Tags tags={post.tags} />
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/blog/${previous.slug}`} rel='prev'>
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/blog/${next.slug}`} rel='next'>
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug(
        $slug: String!
        $previousPostSlug: String
        $nextPostSlug: String
    ) {
        contentfulBlogPost(slug: { eq: $slug }) {
            slug
            title
            author {
                name
            }
            publishDate(formatString: "MMMM Do, YYYY")
            rawDate: publishDate
            heroImage {
                gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
                resize(height: 630, width: 1200) {
                    src
                }
            }
            body {
                raw
            }
            tags
            description {
                raw
            }
            sys {
                contentType {
                    sys {
                        id
                    }
                }
            }
        }
        previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
            slug
            title
        }
        next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
            slug
            title
        }
    }
`
